$primary: #1c1c1c !default; $secondary: #f9fcff !default; $tertiary: #1d6d6d !default; $quaternary: #cb3727 !default; $header_background: #cb3727 !default; $header_primary: #ffffff !default; $highlight: #e6cb37 !default; $danger: #d05454 !default; $success: #71bd9f !default; $love: #c16ad7 !default; $selected: #d2edf3 !default; $hover: #d2edf3 !default;  $public_image_path: "https://ghdev.cnvc.org/images";  @import "common/foundation/variables"; @import "common/foundation/mixins"; $custom_header_links: unquote('[{"url"=>"https://cnvc.org", "text"=>"CNVC.org", "view"=>"vdm", "title"=>"this link will open in a new tab", "locale"=>"en", "target"=>"blank", "hide_on_scroll"=>"remove"}]');$links_position: unquote("right");// Theme: Custom Header Links
// Target: common scss
// Last Edited: 2023-09-18 19:16:09 UTC
.before-header-panel-outlet {
  display: flex;
}

@if $links_position == "right" {
  .before-header-panel-outlet {
    margin-left: auto;
    + .panel {
      margin-left: 0;
    }
  }
}

.custom-header-links {
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  @if $links_position == "left" {
    margin-left: 1em;
    &--hide-links {
      display: none;
    }
  }

  .headerLink {
    list-style: none;
    a {
      padding: 0.35em 0.6em;
      color: var(--header_primary);
      font-size: var(--font-up-1);
    }
  }

  &--hide-links {
    .headerLink:not(.headerLink--keep) {
      display: none;
    }
  }
}

.desktop-view .headerLink--vmo,
.mobile-view .headerLink--vdo {
  display: none;
}

// for compatibility with the discourse-header-search component

.floating-search-input-wrapper {
  flex: 1 1 auto;
  margin: 0 1em;
  @if $links_position == "left" {
    order: 2;
  }

  .floating-search-input {
    width: 100%;
    margin: 0;
  }

  .search-menu {
    width: 100% !important; // overrides very specific selector
  }
}
